.container {
  position: relative;
  /* max-height: 100px; */
  /* overflow-y: hidden; */

}

.viewerProse {
  code {
    font-size: 90%;
    background-color: #edeef1;
    padding: 1px 0.25rem;
  }
}

.placeholder {
  font-weight: 300;
}

.fadeOverlay::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(to bottom, transparent, white);
  pointer-events: none;
}