.EmojiButton.EmojiButton.EmojiButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  font-size: 20px;

  /* background-color: rgba(134, 134, 134, 0.25);
  transition: background-color 0.3s ease;
  border-radius: 4px;

  &:hover {
    background-color: silver;
  } */

}

/* .isFocused {
  background-color: silver;
} */

/* Styling for the popover */

/* .popoverContent {
  border-radius: 4px;
  background-color: transparent;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.popoverContent[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}

.popoverContent[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}

.popoverContent[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}

.popoverContent[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
} */

.popoverContent {
  --popover-content-padding: 0px !important;

  :global(.epr-header) {
    display: none;
  }

  :global(.epr-main) {
    border: 0 none;

    >div:last-child {
      display: none;
    }
  }
}

/* .popoverArrow {
  fill: white;
} */

/* @keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
} */