.container {
  min-width: 100vw;
  min-width: 100dvw;
  max-width: 100vw;
  max-width: 100dvw;
  min-height: 100vh;
  min-height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;

  overflow: hidden;
  display: grid;

  /* flex-direction: column; */
  /* mobile maybe */
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'taskList userList'

    /* display: grid;
  gap: 5px;
  grid-template-columns: 1.25fr 1fr 0.75fr; */
    /* 25% 左侧, 50% 中间, 25% 右侧 */
    /* grid-template-rows: repeat(2, 1fr); */
    /* Adjust height based on content */
    /* grid-template-areas:
    'item5 item1 item3'
    'item5 item2 item4'; */
}

/* :root {
  --deep-green: #386641;
  --light-green: #6a994e;
  --forest-green: #4f772d;
  --sage-green: #90a955;
  --silver: silver;
  --light-blue: #9dc4f5;
  --urgent-light-red: #ffebeb;
  --light-pink: #ffd0e2;
} */

* {
  transition: none;
}

/* @define-mixin align-right {
  justify-self: end;
  margin-left: auto;
} */

.droppableContainer {
  overflow: hidden;
  border-radius: 4px;
  /* min-width: 300px;
  max-width: 400px; */
}

.userList {
  grid-area: userList;
  max-width: inherit;

  --container-primary-color: var(--silver);
  background-color: var(--container-primary-color);
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  overflow: auto;

  /* display: flex;
  flex-direction: column;
  gap: 10px; */

  .droppableContainer {
    border: 1px solid white;
    margin: 10px;
  }
}


.taskList {
  overflow: hidden;
  grid-area: taskList;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  grid-template-rows: repeat(2, 1fr);

  /* use minmax: the height can be flexible according to the content */
  /* grid-template-rows: repeat(2, minmax(1fr, 0.62fr)); */
  /* grid-template-rows: minmax(200px, 1fr), minmax(200px, 1fr); */
  grid-template-areas:
    'item1 item2'
    'item3 item4';

  .droppableContainer {
    min-height: 200px;
    background-color: var(--container-primary-color);
    position: relative;
    border-radius: 0;
  }


  .droppableContainer:nth-child(1) {
    grid-area: item1;

    /* background: var(--deep-green); */
    --container-primary-color: #a1c4fd;
    background-image: linear-gradient(to top, #a1c4fd 0%, #c2e9fb 100%);
    /* @mixin align-right; */
  }

  .droppableContainer:nth-child(2) {
    grid-area: item2;

    --container-primary-color: #fcb69f;
    background-image: linear-gradient(to bottom, #ffecd2 0%, #fcb69f 100%);
    /* background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%); */
  }

  .droppableContainer:nth-child(3) {
    grid-area: item3;

    --container-primary-color: #c1dfc4;
    background-image: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%);
    /* @mixin align-right; */
  }

  .droppableContainer:nth-child(4) {
    grid-area: item4;

    --container-primary-color: #c4c5c7;

    background-image: linear-gradient(to top,
        #c4c5c7 0%,
        #dcdddf 52%,
        #ebebeb 100%);
  }

}


.addNewItemContainer {
  display: flex;
  padding: 4px 10px;
  /* justify-content: space-between; */

  .addNewItemButton.addNewItemButton.addNewItemButton {
    display: flex;
    align-items: center;
    justify-content: center;

    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .addNewItemDropdown {
    display: flex;
    align-items: center;
    justify-content: center;

    .addNewItemDropdownTrigger.addNewItemDropdownTrigger.addNewItemDropdownTrigger {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 5px;
    }
  }
}