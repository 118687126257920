/* editor styles */
:root {
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
}

.prose {
  font-family: var(--font-display);
  padding: var(--spacing-1) var(--spacing-2);
  /* width 100% can not be removed, check `blockquote` before you move it */
  width: 100%;
  white-space: normal;
  text-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  line-height: 1.5rem;
}

.prose h1 {
  font-size: 1.5em;
}

.prose h2 {
  font-size: 1.25em;
}

.prose h3 {
  font-size: 1.125em;
}

.prose h4 {
  font-size: 1em;
}

.prose h5 {
  font-size: 0.875em;
}

.prose h1 {
  background-image: linear-gradient(45deg,
      hsl(240deg 100% 20%) 0%,
      hsl(281deg 100% 21%) 8%,
      hsl(304deg 100% 23%) 17%,
      hsl(319deg 100% 30%) 25%,
      hsl(329deg 100% 36%) 33%,
      hsl(336deg 100% 41%) 42%,
      hsl(346deg 83% 51%) 50%,
      hsl(3deg 95% 61%) 58%,
      hsl(17deg 100% 59%) 67%,
      hsl(30deg 100% 55%) 75%,
      hsl(40deg 100% 50%) 83%,
      hsl(48deg 100% 50%) 92%,
      hsl(55deg 100% 50%) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* TODO: now it is not working without !important, gets overridden by tailwind base styles */
  margin-bottom: var(--spacing-1) !important;
  font-weight: bold !important;
  line-height: 1.25;

  code {
    background-color: transparent;

    span {
      background-color: transparent;
    }
  }
}

.prose h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

.prose a {
  text-decoration: underline;
  transition: color 0.5s ease;
  color: var(--blue-10);
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.prose p {
  padding: 0;
  margin: 2px 0;
  line-height: 1.25rem;
}

.prose ul,
.prose ol {
  padding-inline-start: var(--spacing-6);
}

.prose ul {
  list-style-type: disc;
  grid-gap: 0;

  li {
    margin: 0;
  }
}

.prose ol {
  list-style-type: decimal;

  li {
    margin: 0;
  }
}

.prose ul li {

  /* for editor todo items */
  &[role="checkbox"],
  /* for viewer todo items */
  &.task-list-item {
    list-style-type: none;
    position: relative;
    margin-left: calc(var(--spacing-6) * -1);
    padding-right: var(--spacing-1);
    padding-left: var(--spacing-6);

    &::before {
      visibility: hidden;
    }

    &::after {
      cursor: pointer;
      content: '';
      /* use svg check mark icon */
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border: 0 none;
      transform: none;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      left: 2px;
    }

    &[aria-checked='false']::after,
    &:has(input[type='checkbox']:not(:checked))::after {
      background-image: url('../../assets/checkBoxUnchecked.svg');
    }

    &[aria-checked='true']::after,
    &:has(input[type='checkbox']:checked)::after {
      background-image: url('../../assets/checkBoxChecked.svg');
    }

    &[aria-checked='true'],
    &:has(input[type='checkbox']:checked) {
      /* text-decoration: strike-through; */
      /* strike-through does not align in the middle when `code` and text are together */
      /* https://stackoverflow.com/questions/8215754/css-strike-through-not-centered-through-the-text */

      text-decoration: underline;
      text-underline-offset: -40%;
      text-decoration-skip-ink: none;

      /* This also works well when there are links, now links are not underlined from todo item marked as DONE */
    }

    >input[type='checkbox'] {
      display: none;
      visibility: hidden;
    }

    /* For todo items, hide the outline */
    &:focus-visible {
      outline: 0 none;
    }
  }
}

.prose code {
  >span {
    font-size: 90%;
  }
}

/* ONLY for Viewers TODO items */
.prose ul li.task-list-item {

  /* clone from MDXEditor prose styles, which is missing for viewer */
  code {
    background-color: #edeef1;
    padding: 1px 0.25rem;
    font-family: monospace;
    font-size: 90%;
  }
}

.prose blockquote {
  font-style: italic;
  font-weight: 400;
  padding-inline-start: 0.5em;
  padding: 4px 6px;
  margin: 2px 0;
  border-left: 3px solid silver;
  background-color: #8080801c;
  border-radius: 0 5px 5px 0;
  /* background-image: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%); */

  /* Render different elements in blockquote */
  /* - Editor: span data-lexical-text="true" */
  /* - Viewer: p */
  & span,
  & p {
    display: inline-block;
    padding: 0;
    margin: 2px 0;
    line-height: 1.25;
  }
}

.prose :where(code):not( :where([class~='not-prose'], [class~='not-prose'] *))::before {
  content: '' !important;
}

.prose :where(code):not( :where([class~='not-prose'], [class~='not-prose'] *))::after {
  content: '' !important;
}

/* This helps to remove the "`" pair around the code element */
.prose :where(code):not( :where([class~='not-prose'], [class~='not-prose'] *))::before {
  content: '' !important;
}

.prose :where(code):not( :where([class~='not-prose'], [class~='not-prose'] *))::after {
  content: '' !important;
}