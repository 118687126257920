.container {
  /* width: 100%; */
  transition: height 0.25s ease-in-out;
  /* overflow: hidden; */

  .editorContainer {
    /* display: flex;
    flex-grow: 1;
    background-color: pink; */

  }

  .setActiveButton {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
    background: none;
    /* cursor: default; */

    &:hover {
      outline: 1px auto var(--blue-8);
    }

    &:focus-visible {
      outline: 1px auto var(--blue-8);
    }
  }


}

.popoverContent.popoverContent.popoverContent {

  width: 355px;
  /* iPhone SE 375px - 2 * 10px margin */
  padding: 0;
  animation: none;

  &[data-side="top"] {
    transform: translateY(var(--data-distance-to-bottom));
  }
}

.saveButtonContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;

  button {
    cursor: pointer;
  }
}