@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@import './styles/global.css';
@import '@radix-ui/themes/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
  /* disable the mobile browser feature: scroll to refresh */
  overscroll-behavior: none;
}

:root {
  /* font-family: Inter, Avenir, Helvetica, Arial, sans-serif; */
  /* font-family: "Sansita Swashed", system-ui, Helvetica, Arial, sans-serif; */

  /* --font-display: "Lato", system-ui, Helvetica, Arial, sans-serif; */
  --font-display: 'Lato', 'LXGW WenKai Screen', system-ui, Helvetica, Arial,
    sans-serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;

  font-family: var(--font-display);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light;
  /* TODO: add dark theme */
  /* color-scheme: light dark; */

  /* color: rgba(255, 255, 255, 0.87); */
  /* background-color: #242424; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  margin: 0 !important;
  padding: 0 !important;
}

a {
  font-weight: 500;
  color: var(--blue-10);
  /* text-decoration: inherit; */
}

a:hover {
  color: var(--blue-11);
}

button {
  /* this color is applied to all `ghost` variant buttons */
  /* --accent-a11: #000000 !important; */
}

/* scroll bar cross-platform restyling */

/* ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    -webkit-border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8 !important;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07);
    background-color: white;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: white;
  } */

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

/* @media (prefers-color-scheme: dark) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
} */