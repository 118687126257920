.editorContainer {
  width: 100%;
  box-sizing: border-box;
  /* border: 1px solid transparent; */
  /* transition: border-color 0.2s ease-in; */

  > :global(.mdxeditor) {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    :global(.editor) {
      width: 100%;
    }

    :global(.mdxeditor-toolbar) {
      /* opacity: 0;
      visibility: hidden;
      transition: opacity 0.25s ease-in-out; */

      /* position: absolute;
      z-index: 10;
      top: -29px;
      left: 0;
      right: 0;
      height: 28px; */

      overflow: hidden;
      padding: 0;
      margin: 0;
      /* border-radius: 5px 5px 0 0; */
      margin-top: 8px;
      margin-bottom: 8px;
      border-radius: 0;
      /* border-top: 1px solid rgb(0 0 0 / 0.20); */
      background-color: transparent;
    }

    .editorToobar {
      display: flex;
      margin: 0 var(--spacing-1);

      div[class*='toolbarGroupOfGroups'] {
        margin: 0;
      }
    }
  }

}

/* customize popup  */

:global(.mdxeditor-popup-container) {
  /* transition: opacity 0.25s ease-in; */

  >div[data-radix-popper-content-wrapper] {
    filter: drop-shadow(0 3px 3px rgb(0 0 0 / 0.3));
  }
}